<template>
  <NCard
    id="TrainingAssignment"
    title="实训任务书"
    :segmented="{ content: 'hard' }"
  >
    <NSteps :current="currentStep" status="process" style="width: 75%; padding: 15px; margin-bottom: 20px;">
      <NStep title="基本信息" />
      <NStep title="实训内容" />
    </NSteps>
    <Step1
      v-show="currentStep === 1"
      ref="step1Ref"
      :material-id="materialId"
    />
    <Step2 ref="step2Ref" v-show="currentStep === 2" />
    <template #footer>
      <NSpace size="large">
        <template v-if="currentStep === 1">
          <NButton size="large" style="width: 120px;" @click="handleClickSaveBack">保存并返回</NButton>
          <NButton size="large" style="width: 120px;" type="primary" @click="handleClickSaveNext">保存并下一步</NButton>
        </template>
        <template v-else-if="currentStep === 2">
          <NButton size="large" style="width: 120px;" @click="preStep">上一步</NButton>
          <NSpace size="small">
            <NButton
              size="large"
              style="width: 84px;"
              type="primary"
              ghost
              tag="a"
              target="_blank"
              href="/full-page/task-preview"
              @click="handlePreview"
            >实训预览</NButton>
            <NButton size="large" style="width: 120px;" type="primary" @click="handleDone">完成</NButton>
          </NSpace>
        </template>
      </NSpace>
    </template>
  </NCard>

  <BeforeSubmitUpload
    ref="beforeSubmitUploadRef"
    @all-file-success="handleAllFileSuccess"
    @upload-error="handleUploadError"
  />

  <PageLoading :loading="loading" />
</template>

<script setup>
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import Step1 from './components/Step1.vue';
  import Step2 from './components/Step2.vue';
  import BeforeSubmitUpload from '@/components/BeforeSubmitUpload/BeforeSubmitUpload.vue';

  import { PostTaskUpdate, PostTaskCreate, GetTeachingSystem, GetTaskDetail } from '@/api/task.js';  // 编辑提交 新增提交 教学系统列表 实训详情
  import { PostHomeIndex } from '@/api/admin-home.js';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { editTypeEnum } from '@/views/material/index/build/enum/edit-type-map.js';
  import { toRaw } from '@vue/reactivity'

  const { SUCCESS } = resStatusEnum;
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const message = useMessage();

  const currentStep = ref(1);
  const nextStep = () => {
    currentStep.value++;
  };
  const preStep = () => {
    currentStep.value--;
  };

  const materialId = ref(route.query.id);

  const step1Ref = ref(null);
  const step2Ref = ref(null);

  const handleClickSaveBack = () => {
    step1Ref.value.triggerFormValidator().then(reqData => {
      loading.value = true;
      
      if(reqData['Task[id]'] == undefined&&materialId.value == undefined){
      //  新增
        PostTaskCreate(reqData).then(res => {
          if (res.code === SUCCESS) {
            message.success('保存成功');
            router.push('/task/index/index');
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      }else{
        if(reqData['Task[id]']==undefined){
          reqData['Task[id]'] = materialId.value
        }
      //  编辑
        PostTaskUpdate(reqData).then(res => {
          if (res.code === SUCCESS) {
            message.success('保存成功');
            router.push('/task/index/index');
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      }
    });
  };
  const handleClickSaveNext = () => {
    loading.value = true;
    setTimeout(function(){
      step1Ref.value.triggerFormValidator().then(reqData => {
      if(reqData['Task[id]'] == undefined&&materialId.value == undefined){
        //  新增
        PostTaskCreate(reqData).then(res => {
          if (res.code === SUCCESS) {
            materialId.value = res.data.id
            message.success('保存成功');
            nextStep();
          }
        }).catch(err => {}).finally(() => {
          loading.value = false;
        });
      }else{
          if(reqData['Task[id]']==undefined){
            reqData['Task[id]'] = materialId.value
          }
          //  编辑
          PostTaskUpdate(reqData).then(res => {
            if (res.code === SUCCESS) {
              message.success('保存成功');
              nextStep();
            }
          }).catch(err => {}).finally(() => {
            loading.value = false;
          });
      }
    });
    loading.value = false;
    },1500)
    
  };
  const handlePreview = () => {
    window.localStorage.setItem('MATERIAL_PREVIEW', JSON.stringify(step2Ref.value.getRunningChapters()));
  };

  let reqChapters = [];
  let hasFileMudules = [];
  const handleDone = () => {
    loading.value = true;
    step2Ref.value.cancelAllRename();
    if(step2Ref.value.teachingSystem().length==0){
      message.error('请选择实训教学系统');
      loading.value = false;
      return
    }else{
      reqChapters = step2Ref.value.getChapters();
      hasFileMudules.splice(0);

      const reqFilesSet = [];

      reqChapters.forEach(chapter => {
        chapter.model.forEach(chapterModule => {
          const { fileList } = chapterModule;
          if (Array.isArray(fileList) && fileList.length > 0) {
            hasFileMudules.push(chapterModule);
            reqFilesSet.push(fileList);
          } else {
            delete chapterModule.fileList;
          }
        });
        chapter.section.forEach(section => {
          section.model.forEach(sectionModule => {
            const { fileList } = sectionModule;
            if (Array.isArray(fileList) && fileList.length > 0) {
              hasFileMudules.push(sectionModule);
              reqFilesSet.push(fileList);
            } else {
              delete sectionModule.fileList;
            }
          });
        });
      });
      beforeSubmitUploadRef.value.startUpload(reqFilesSet);
    }
  };

  function addFileReqData(fileIds) {
    hasFileMudules.forEach(moduleData => {
      moduleData.fileList.forEach(() => {
        moduleData.data.push({
          type: '3',
          list: fileIds.shift()
        });
      });
      delete moduleData.fileList;
    });
  }
  function getFileIds(list) {
    loading.value = true
    PostTaskCreate({
      'Task[upload]': list
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        addFileReqData(data);
        setTimeout(submitFn, 0);
      }
    }).finally(() => {
      loading.value = false
    });
  }
  function submitFn() {
    loading.value = true;
    PostTaskUpdate({
      'Task[id]': materialId.value,
      'Task[type]': editTypeEnum.CHAPTER_SECTION,
      'Task[chapter]': reqChapters,
      'Task[system]': step2Ref.value.teachingSystem()
    }).then(res => {
      if (res.code === SUCCESS) {
        message.success('保存成功');
        router.push('/task/index/index');
      }
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  const beforeSubmitUploadRef = ref(null);
  function handleAllFileSuccess(filesSet) {
    if (Array.isArray(filesSet) && filesSet.length > 0) {
      getFileIds(filesSet.flat().map(({
        ext,
        fileType,
        fileUrl,
        name,
        size,
        videoId
      }) => ({
        file_url: fileUrl,
        file_name: name,
        file_size: size,
        extension: ext,
        file_type: fileType,
        video_id: videoId
      })));
    } else {
      submitFn();
    }
  };
  function handleUploadError() {
    loading.value = false;
    console.log('有文件上传失败，返回');
  };

  loading.value = true;
  GetTaskDetail({
    'Task[id]': materialId.value
  }).then(res => {
    if (res.code === SUCCESS) {
      if(res.data.image_url!=''){
        let imgURL = [{ name: "cc.png", url:res.data.image_url, status: "finished" }]
        res.data.image_url = imgURL
      }else{
        res.data.image_url = []
      }
      // console.log("res.data.chapter",res.data.chapter)
      step1Ref.value.initFormValue(res.data);
      step2Ref.value.initData(res.data.chapter);
    }
  }).catch(err => {}).finally(() => {
    loading.value = false;
  });

  // 在当前页面时 每半小时请求接口 刷新验签 防止登录过期
  setInterval(function(){
    PreventExpiration()
  },1800000)
  function PreventExpiration(){
    PostHomeIndex().then(res=>{
    }).catch(err=>{})
  }
  PreventExpiration()
</script>
<style lang="less">
#TrainingAssignment .n-card-header .n-card-header__main{
  font-weight: 700;
}
</style>
