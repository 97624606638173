<template>
  <div id="PracticalTraining-basic">
    <NForm
      ref="formRef"
      label-placement="left"
      :label-width="120"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="实训标题：" path="title">
        <NInput
          maxlength="50"
          show-count
          clearable
          style="width: 500px;"
          v-model:value="formValue.title"
        />
      </NFormItem>

      <NFormItem path="image_url" label="实训封面：" show-require-mark class="uploader-container">
        <!-- 头像上传 -->
        <UPheadPortrait text="限上传PNG、JPG格式图片 最佳设计宽高比 200:130，大小不超过2M" 
        v-if="isOne"
        @imagedata="imagedata" 
        size="2" 
        removeUrl="/backend/web/upload/delete" 
        :imaData="imageURL"/>
        <!-- 头像上传 -->
        <UPheadPortrait text="限上传PNG、JPG格式图片 最佳设计宽高比 200:130，大小不超过2M"
        v-else
        @imagedata="imagedata" 
        size="2" 
        removeUrl="/backend/web/upload/delete" 
        :imaData="imageURL"/>
      </NFormItem>
      
      <NFormItem label="实训简介：" path="introduce">
        <NInput
          placeholder="请输入备注"
          type="textarea"
          maxlength="500"
          show-count
          clearable
          style="width: 500px;"
          v-model:value="formValue.introduce"
        />
      </NFormItem>
    </NForm>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import PageLoading from '@/components/PageLoading/index.vue';
  import UPheadPortrait from '@/components/UPheadPortrait/index.vue';
  import { editTypeEnum } from '@/views/material/index/build/enum/edit-type-map.js';
  import { toRaw } from '@vue/reactivity'
  const route = useRoute();
  const router = useRouter();
  const teachingId = route.query.id;
  const isEdit = !!teachingId;
  const message = useMessage();
  const loading = ref(false);
  const imageURL = ref([])
  const props = defineProps({
    materialId: {
    }
  });

  const resReqDataMap = {
    id: {
      reqKey: 'Task[id]',
      default: teachingId
    },
    type: {
      reqKey: 'Task[type]',
      default: editTypeEnum.MATERIAL
    },
    title: {
      reqKey: 'Task[title]',
      default: '',
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    },
    introduce: {
      reqKey: 'Task[introduce]',
      default: ''
    },
    image_url: {
      reqKey: 'Task[image_url]',
      default: '' // 图片url
    },
  };

   const getDefaultFormValue = () => {
    const tempFormValue = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempFormValue[key] = resReqDataMap[key].default;
    });
    return tempFormValue;
  };
  
  const isOne = ref(false)
  // 父子组件传值 封面
  
  const imagedata = (params)=> {
    console.log("父子组件传值",params)
    if(params.length!=0){
      isOne.value = true
      let data = {
        url:params[0].url,
        name:params[0].reallyname
      }
      resReqDataMap['image_url'].default = params[0].url
      imageURL.value = [{ name: 'cc.png', url:params[0].url, status: 'finished'}]
    }else{
      isOne.value = false
      resReqDataMap['image_url'].default = ''
      imageURL.value = []
    }
  }

  const formRef = ref(null);
  const formValue = reactive(getDefaultFormValue());
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqDataMap).forEach(key => {
      const rule = resReqDataMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();

  const getReqData = () => {
    const tempReqData = {};
    Object.keys(resReqDataMap).forEach(key => {
    
      if(key!='image_url'){
        tempReqData[resReqDataMap[key].reqKey] = formValue[key]
      }else{
        if(imageURL.value.length==0){
          loading.value = false
          // message.error('封面不能为空');
        }else{
          tempReqData[resReqDataMap[key].reqKey] = imageURL.value[0].url;
        }
      }
    });
    return tempReqData;
  };

  const triggerFormValidator = () => {
    return new Promise(resolve => {
      formRef.value.validate(errors => {
        if (!errors) {
          resolve(getReqData());
        }
      });
    });
  };

  const initFormValue = resData => {
    Object.keys(resReqDataMap).forEach(key => {
      switch (key) {
        case 'title':
        case 'introduce':
          formValue[key] = resData[key] || resReqDataMap[key].default;
        case 'image_url':
          formValue['image_url'] = resData[key] || resReqDataMap[key].default;
          imageURL.value = resData[key] || resReqDataMap[key].default;
          if(toRaw(imageURL.value.length)!=0){
            if(toRaw(imageURL.value)[0].url == undefined){
              isOne.value = false
              imageURL.value = []
            }else{
              isOne.value = true
            }
          }
          break;
      }
    });
  };

  // formValues.value["Task[image_url]"] = [{ name: "cc.png", url:res.data.image_url, status: "finished" }], // 图标url数组
  defineExpose({
    triggerFormValidator,
    initFormValue
  });
</script>
<style lang="less">
#PracticalTraining-basic .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
  width: 200px !important;
  height: 130px !important;
  position: relative;
  position: relative;
  background: #FAFAFC url(../../../../../public/img/upload/C6173F69.png) no-repeat center center;
  background-size: 50% !important;
}
#PracticalTraining-basic .n-upload-file.n-upload-file--info-status.n-upload-file--image-card-type+.n-upload-trigger.n-upload-trigger--image-card{
  display: none !important;
}
#PracticalTraining-basic .title-up-text {
  position: relative;
  left: 200px !important;
  color: #c2c2c2;
}
#PracticalTraining-basic .n-upload-file-list .n-upload-file.n-upload-file--image-card-type{
  width: 200px !important;
  height: 130px !important;
}
#PracticalTraining-basic .n-upload-file-list .n-upload-file.n-upload-file--image-card-type + .n-upload-trigger.n-upload-trigger--image-card{
  display: none !important;
}
#PracticalTraining-basic .title-up-text{
  width: 144px !important;
}
</style>
<style lang="less" scoped>
  .uploader-container {
    :deep(.n-form-item-blank) {
      align-items: flex-start;
      height: 130px;
    }
    :deep(.n-upload-file-info__thumbnail img) {
      object-fit: contain !important;
    }
  }
</style>