<template>
  <div>
    <NSpace style="margin-bottom: 10px">
      <NButton @click="handleClickImport">导入架构</NButton>
      <NButton type="primary" @click="handleClickExport">导出架构</NButton>
    </NSpace>
    <NGrid :x-gap="15" :cols="24">
      <NGi :span="8">
        <MaterialDir
          ref="materialDirRef"
          :dir-data="dirData"
          @editing-section-change="handleEditingSectionchange"
        />
      </NGi>
      <NGi :span="16">
        <MaterialSection
          ref="materialSectionRef"
          :editing-section="editingSection"
          component-type="task"
          :chapter-section-type="chapterSectionType"
        />
      </NGi>
    </NGrid>

    <ImportDirModal ref="importRef" @import-success="handleImportSuccess" />

    <div class="system-container">

    <NForm
        label-placement="left"
        :label-width="115"
        require-mark-placement="left"

        v-show="schoolValues.length > 0"
        class="system-list-form"
      >

    
    <NFormItem label="实训系统：" path="schoolValues" show-require-mark>
      <!-- 默认的选择列表 -->
      <div>
        <p
          class="school-text1"
          v-for="item in schoolValues"
          :key="item.system_id"
        >
          {{ item.system_name }}
          <n-button text color="#c6241d" @click="deleteATap(item.system_id)">
            <template #icon>
              <n-icon>
                <cash-icon />
              </n-icon>
            </template>
          </n-button>
        </p>
      </div>
    </NFormItem>
    </NForm>
    <NButton type="primary" @click="addAdmin" class="add-school"
      >添加实训教学系统</NButton
    >

    </div>

    <template>
      <n-modal
        class="custom-card"
        v-model:show="showModal"
        preset="card"
        :style="bodyStyle"
        title="添加实训教学系统"
        size="huge"
        :bordered="false"
        :segmented="segmented"
      >
        <template #header-extra></template>
        <n-data-table
          ref="table"
          :columns="columns"
          :data="schoolOptions"
          :pagination="pagination"
          :row-key="(row) => row.system_id"
          :row-value="(row) => row.name"
          :default-checked-row-keys="Pselect"
          remote
          @update:checked-row-keys="handleCheck"
        />
        <template #footer>
          <NButton type="primary" @click="showModal = false" class="school-btn"
            >确定</NButton
          >
        </template>
      </n-modal>
    </template>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { ref, nextTick, reactive } from "vue";
import { useMessage } from "naive-ui";
import { useRouter, useRoute } from "vue-router";

import { CloseCircleSharp as CashIcon } from '@vicons/ionicons5'
import PageLoading from "@/components/PageLoading/index.vue";
import MaterialDir from '@/views/material/index/build/components/MaterialDir.vue';
import MaterialSection from '@/views/material/index/build/components/MaterialSection.vue';
import ImportDirModal from "@/views/material/index/build/components/ImportDirModal.vue";
import { GetTeachingSystem, exportDir } from "@/api/task.js"; // 教学系统列表 实训导出架构
import { generateChapterItem, getChapters } from "@/views/material/index/build/components/tool-fns.js";
import { chapterSection } from "@/views/material/index/build/enum/edit-type-map.js";

const route = useRoute();
const router = useRouter();

const teachingId = route.query.id;

const message = useMessage();
const loading = ref(false);
const materialDirRef = ref(null);
const importRef = ref(null);
const showModal = ref(false); // 添加教学系统弹窗

const handleClickImport = () => {
  importRef.value.openModal();
};
const handleImportSuccess = (chapters) => {
  handleEditingSectionchange({});
  initData(chapters);
};
const handleClickExport = () => {
  loading.value = true;
  const chapters = [];
  dirData.value.forEach(({ title, section_list }) => {
    chapters.push({
      title,
      type: chapterSection.CHAPTER,
    });
    if (Array.isArray(section_list)) {
      section_list.forEach(({ title }) => {
        chapters.push({
          title,
          type: chapterSection.SECTION,
        });
      });
    }
  });
  exportDir({
    Task: chapters,
  })
    .then((res) => {
      saveAs(
        new Blob([res], {
          type: "application/octet-stream",
        }),
        `章节架构.xlsx`
      );
    })
    .catch((err) => {})
    .finally(() => {
      loading.value = false;
    });
};

const Pselect = ref([]); // 删除的已选ID

const bodyStyle = {
  width: "600px",
};

const schoolOptions = ref([]); // 教学系统列表
const segmented = {
  content: "soft",
  footer: "soft",
};

const columns = [
  {
    type: "selection",
  },
  {
    title: "系统名称",
    key: "system_name",
  },
];

const handleCheck = (rowKeys, values) => {
  // console.log("删掉的",rowKeys)
  Pselect.value = rowKeys;
  SelectValue(rowKeys);
};

const pagination = reactive({
  page: 2,
  pageSize: 30,
  showSizePicker: true,
  pageSizes: [10, 20, 30],
  onChange: (page) => {
    paginationReactive.page = page;
  },
  onPageSizeChange: (pageSize) => {
    paginationReactive.pageSize = pageSize;
    paginationReactive.page = 1;
  },
});

const SelectValue = (rowKeys) => {
  schoolValues.value = [];
  for (var i = 0; i < rowKeys.length; i++) {
    for (var j = 0; j < schoolOptions.value.length; j++) {
      if (schoolOptions.value[j].system_id == rowKeys[i]) {
        schoolValues.value.push({
          system_id: rowKeys[i],
          system_name: schoolOptions.value[j].system_name,
        });
      }
    }
  }
};

const schoolValues = ref([]); // 展示的教学系统数据

// 添加实训系统事件
const addAdmin = () => {
  showModal.value = !showModal.value;
};

// 教学系统列表数据
GetTeachingSystem({
  id: teachingId, // 实训ID
})
  .then((res) => {
    schoolOptions.value = res.data.list;
    Pselect.value = res.data.have_list;
    SelectValue(Pselect.value)
  })
  .catch((err) => {
    loading.value = false;
  });

// 删除教学实训系统
const deleteATap = (id) => {
  Pselect.value.forEach((item2, index2) => {
    if (item2 == id) {
      Pselect.value.splice(index2, 1);
    }
  });
  // console.log("删除教学实训系统",Pselect.value)
  schoolValues.value.forEach((item, index) => {
    if (item.system_id == id) {
      schoolValues.value.splice(index, 1);
    }
  });
};

const dirData = ref([
  /* {
      key: '1-1',
      title: '章1',
      section_list: [
        {
          key: '2-1-1',
          title: '节1-1'
        },
        {
          key: '2-1-2',
          title: '节1-2'
        },
        {
          key: '2-1-3',
          title: '节1-3'
        }
      ]
    },
    {
      key: '1-2',
      title: '章2',
      section_list: [
        {
          key: '2-2-1',
          title: '节2-1'
        },
        {
          key: '2-2-2',
          title: '节2-2'
        }
      ]
    },
    {
      key: '1-3',
      title: '章3',
      section_list: [
        {
          key: '2-3-1',
          title: '节3-1'
        }
      ]
    }, */
]);

const editingSection = ref({});
const chapterSectionType = ref('');
const handleEditingSectionchange = params => {
  editingSection.value = params.data;
  chapterSectionType.value = params.chapterSectionType;
};

const initData = (chapters) => {
  if (Array.isArray(chapters)) {
    try {
      chapters.forEach(item => {
        item.material_chapter_type_list || (item.material_chapter_type_list = item.task_chapter_type_list);
      });
      dirData.value = chapters.map((chapterItem, chapterIndex) =>
        generateChapterItem(chapterItem, chapterIndex)
      );
    } catch (e) {
      console.log(e);
    }
  } else {
    dirData.value = [generateChapterItem()];
  }
  nextTick(() => {
    materialDirRef.value.updateDirDataItemTags();
    materialDirRef.value.expandAllChapters();
  });
};

const materialSectionRef = ref(null);

defineExpose({
  initData,
  getChapters: () => {
    return getChapters(dirData.value);
  },
  getRunningChapters: () => {
    return dirData.value;
  },
  teachingSystem: () => {
    return Pselect.value;
  },
  cancelAllRename: () => {
    materialDirRef.value.cancelItem();
    materialSectionRef.value.cancelAllModuleNameRename();
  },
});
</script>
<style lang="less" scoped>
.school-btn{
  width: 120px;
}
.add-school{
  margin-left: 25px;
  margin-bottom: 15px;
}
.school-text1{
  margin-top:6px;
  width: 380px;
}
.school-text1 .n-button{
  position: relative;
  top:4px;
  left:10px
}

  .system-container {
    margin: 15px 0 0;
    padding-top: 15px;
    border: 1px solid #eee;
  }
  .system-list-form {
    margin-bottom: -15px;
  }
</style>
